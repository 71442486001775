export const RouteKeys = {
  Index: '/',
  Login: 'login',
  OrderHistory: 'order-history',
  MyPage: 'my-page',
  VerifyMobile: 'verify-mobile',
  PermissionDenied: 'permission-denied',
  RegisterFinish: 'register-finish',
  Shop: 'shop',
  Id: 'id',
  Cart: 'cart',
  Error404: '404',
  Sites: 'sites',
  Product: 'product',
  Order: 'order',
  PayCallback: 'pay-callback',
  Finish: 'finish',
  ProductId: 'productId',
  ShopId: 'shopId',
  Terms: 'terms',
  Document: 'document',
  DeliveryDocument: 'delivery-document',
  OrderDocument: 'order-document',
  Coupons: 'coupons',
  KakaoCallback: 'kakao-callback'
}

export const QueryKeys = {
  LogoutFlag: 'logout-flag',
  RefreshExpired: 'refresh-expired',
  SiteSlug: 'site',
  OrderNumber: 'order-number',
  Pin: 'pin',
  ScenarioNumber: 'scenario-number',
  MerchantUid: 'merchant_uid',
  beforeHistoryLength: 'beforeHistoryLength',
  ImpSuccess: 'imp_success',
  Redirect: 'redirect_to',
  RobotName: 'robot-name',
  PointPaid: 'point-paid',
  OrderId: 'order-id',
  Search: 'search',
  ChangeSite: 'change-site',
  SiteSlug2: 'siteSlug'
}

export const Routes = {
  Home: `/`,
  Login: `/${RouteKeys.Login}`,
  OrderHistory: `/${RouteKeys.OrderHistory}`,
  OrderHistoryDetail: `/${RouteKeys.OrderHistory}/[${RouteKeys.Id}]`,
  OrderHistoryDocument: `/${RouteKeys.OrderHistory}/${RouteKeys.Document}`,
  MyPage: `/${RouteKeys.MyPage}`,
  VerifyMobile: `/${RouteKeys.VerifyMobile}`,
  RegisterFinish: `/${RouteKeys.RegisterFinish}`,
  Shop: {
    Index: `/${RouteKeys.Shop}/[${RouteKeys.ShopId}]`,
    Product: `/${RouteKeys.Shop}/[${RouteKeys.ShopId}]/${RouteKeys.Product}/[${RouteKeys.ProductId}]`,
    PermissionDenied: `/${RouteKeys.Shop}/${RouteKeys.PermissionDenied}`
  },
  Cart: `/${RouteKeys.Cart}`,
  Sites: `/${RouteKeys.Sites}`,
  Error404: `/${RouteKeys.Error404}`,
  Order: {
    Index: `/${RouteKeys.Order}`,
    Coupon: `/${RouteKeys.Order}/${RouteKeys.Coupons}`
  },
  OrderPayCallback: `/${RouteKeys.Order}/${RouteKeys.PayCallback}`,
  OrderFinish: `/${RouteKeys.Order}/${RouteKeys.Finish}`,
  Terms: `/${RouteKeys.Terms}`,
  DeliveryDocument: `/${RouteKeys.DeliveryDocument}`,
  OrderDocument: `/${RouteKeys.OrderDocument}`,
  DocumentFinish: `/${RouteKeys.OrderDocument}/${RouteKeys.Finish}`
}

import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { useState } from 'react'
import { isServer } from '@/pages/_app'
import { useSitesList, useSitesSlugRetrieve } from '@/api/generated/hooks'
import { createContainer } from 'unstated-next'
import useAllList from '@/hooks/query/useAllList'
import { NumberUtils } from '@/utils/design-system/numberUtils'
import { syncSiteSlug } from '@/api/mutator/custom-instance'

const useSiteSlug = () => {
  const [siteSlug, setSiteSlug] = useState(isServer ? undefined : syncSiteSlug() ?? undefined)

  const { data: site } = useSitesSlugRetrieve(siteSlug ?? '', {
    query: {
      enabled: !!siteSlug,
      cacheTime: 60 * 60 * 1000
    }
  })

  const { list: sites } = useAllList({
    queryFn: useSitesList,
    enabled: NumberUtils.isNumber(site?.company),
    queryParams: { companies: site?.company as unknown as number[] }
  })

  const updateSiteSlug = (slug: string) => {
    setSiteSlug(slug)
    LocalStorage.setItem(LocalStorageKeyEnum.SiteSlug, slug)
  }

  return { siteSlug, site, sites, updateSiteSlug }
}

const SiteSlugContainer = createContainer(useSiteSlug)

export default SiteSlugContainer
